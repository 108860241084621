import { useState } from 'react';
import Input from '~/components/Input';
import api from '~/services/api';
import { showNotification } from '~/utils/notificationBoxes';
import { HTTPErrorCallback } from '~/Helpers/Error';
import { Form } from '@unform/web';
import CpfForm from './Components/CpfForm';
import PasswordForm from './Components/PasswordForm';
import { useHistory } from 'react-router-dom';
import UseGlobalModalStructure from '~/hooks/UseGlobalModalStructure';
import SSOBody from './Components/SSOBody';
import ConfirmDeleteBalance from './Components/ConfirmDeleteBalance';
import { cpf as cpfLib, cnpj } from 'cpf-cnpj-validator';
import { cpfcnpjMask } from '~/utils';
import * as S from './styles';
import ReCaptcha from '~/components/Global/Recaptcha';
import UseTwoFactor from '~/hooks/UseTwoFactor';
import SSOTurnoff from './Components/SSOTurnoff';

const Login = ({
  changePage,
  cpf,
  setCPF,
  password,
  setPassword,
  setLoading,
  setQuestions,
  setUsuID,
  setTempToken,
  setChannelID,
  setOptIn,
  setUsuCargo,
}) => {
  const {
    addGlobalModalStructure,
    removeGlobalModalStructure,
  } = UseGlobalModalStructure();
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState('cpf');
  const [reCaptchaToken, setReCaptchaToken] = useState(null);
  const { openModal, setCallback } = UseTwoFactor();

  const deleteBalance = (url, limit_date, channel_name, balance, token) => {
    removeGlobalModalStructure();
    addGlobalModalStructure(
      <ConfirmDeleteBalance
        balance={balance}
        confirm={() => {
          setLoading(true);
          api
            .delete('/desistir-do-saldo', {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((response) => {
              setLoading(false);
              window.location.reload();
            })
            .catch((error) => {
              setLoading(false);
            });
        }}
        cancel={() => {
          removeGlobalModalStructure();
          openMigrationSSOModal(url, limit_date, channel_name, balance, token);
        }}
      />,
      false,
      () => {},
      false,
    );
  };

  const openMigrationSSOModal = (
    url,
    limit_date,
    channel_name,
    balance,
    token,
  ) => {
    addGlobalModalStructure(
      <SSOBody
        url={url}
        limit_date={limit_date}
        channel_name={channel_name}
        balance={balance}
        deleteBalance={() =>
          deleteBalance(url, limit_date, channel_name, balance, token)
        }
      />,
      false,
      () => {},
      false,
    );
  };

  const openTurnoffSSOModal = (
    url,
    limit_date,
    channel_name,
    balance,
    token,
  ) => {
    addGlobalModalStructure(
      <SSOTurnoff
        url={url}
        limit_date={limit_date}
        balance={balance}
        deleteBalance={() =>
          deleteBalance(url, limit_date, channel_name, balance, token)
        }
      />,
      false,
      () => {},
      false,
    );
  };

  const login = async (data) => {
    if (!data.cpf) {
      return showNotification(
        'danger',
        'Atenção',
        'Preencha o CPF ou CNPJ antes de continuar!',
      );
    }
    if (data.cpf.length <= 14) {
      if (!cpfLib.isValid(data.cpf)) {
        return showNotification('danger', 'Atenção', 'Insira um CPF válido!');
      }
    } else if (!cnpj.isValid(data.cpf)) {
      return showNotification('danger', 'Atenção', 'Insira um CNPJ válido!');
    }

    const callAPI = async (digits, setOpen, callback) => {
      setLoading(true);

      try {
        const response = await api.post('/login', {
          cpf,
          password,
          recaptcha: reCaptchaToken,
          digits,
        });
        const {
          opt_in,
          register_secret_answers,
          token,
          usu_id,
          questions,
          temp_token,
          user_data,
          token_from_email,
          message,
          migration,
          turnoff,
          channel_name,
          open_two_factor,
        } = response.data;

        if (open_two_factor && callback) {
          setCallback(() => callback);
          setLoading(false);
          return openModal();
        } else setOpen(false);

        if (migration && migration.sso) {
          setLoading(false);
          return openMigrationSSOModal(
            migration.sso,
            migration.limit_date,
            migration.channel_name,
            migration.balance,
            token,
          );
        }

        if (turnoff && turnoff.sso) {
          setLoading(false);
          return openTurnoffSSOModal(
            turnoff.sso,
            turnoff.limit_date,
            turnoff.channel_name,
            turnoff.balance,
            token,
          );
        }

        if (register_secret_answers) {
          setUsuID(usu_id);
          setChannelID(user_data.canal_id);
          setQuestions(questions);
          setTempToken(temp_token);
          setUsuCargo(user_data?.usu_cargo);
          setOptIn(opt_in);
          changePage('secretAnswers');
          setLoading(false);
          return;
        }

        if (opt_in) {
          setUsuID(usu_id);
          setChannelID(user_data.canal_id);
          setTempToken(temp_token);
          setOptIn(opt_in);
          history.push({
            pathname: '/optin',
            usu_id: usu_id,
            temp_token,
            usu_cargo: user_data?.usu_cargo,
          });
          return;
        }

        if (token_from_email) {
          changePage('login');
          setLoading(false);
          return showNotification('success', 'Atenção', message);
        }

        localStorage.setItem('userToken', token);
        localStorage.setItem('canal', user_data.canal_id);
        localStorage.setItem('canalNome', channel_name);
        sendVersion(response.data.user_data.usu_id);
        window.location.reload();
        return setLoading(false);
      } catch (error) {
        changePage('login');
        setLoading(false);
        if (error.response?.data?.error?.includes('código')) {
          setCallback(() => callback);
          openModal();
        }
        return HTTPErrorCallback(error);
      }
    };

    await callAPI('', () => {}, callAPI);
  };

  const sendVersion = async (usu_id) => {
    let version = await fetch('/version.txt').then((response) =>
      response.text(),
    );

    if (version.includes('<!DOCTYPE html>')) version = 'No Version';

    api.post('/login-version/', {
      usu_id,
      version,
    });
  };
  return (
    <>
      <Form onSubmit={login}>
        <p
          style={{
            fontFamily: 'Roboto, sans-serif',
            alignSelf: 'normal',
            fontSize: '14px',
            fontWeight: '500',
          }}
        >
          CPF/CNPJ
        </p>
        <Input
          id="cpf_input"
          name="cpf"
          placeholder="Informe seu CPF ou CNPJ"
          value={cpf}
          onChange={(e) => {
            setCPF(cpfcnpjMask(e.target.value));
          }}
        />
        <S.Forgot onClick={() => changePage('forgotPassword')}>
          <p>Senha</p>
          <p
            id="title"
            style={{
              textDecoration: 'underline',
              cursor: 'pointer',
              margin: 0,
              padding: 0,
              justifyContent: 'flex-end',
            }}
          >
            Esqueci minha senha
          </p>
        </S.Forgot>

        <Input
          id="password_input"
          type="password"
          name="password"
          className="form-input"
          placeholder="Insira sua senha"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <ReCaptcha setReCaptchaToken={setReCaptchaToken} />

        <S.Row>
          <S.Button type="submit">ENTRAR</S.Button>
        </S.Row>
      </Form>
    </>
  );

  // return getComponent(currentStep);
};

export default Login;
