import * as S from './styles';
import moment from 'moment';

const SSOTurnoff = ({ url, limit_date, balance = 0, deleteBalance }) => {
  const days = (date_1, date_2) => {
    let difference = date_1.getTime() - date_2.getTime();
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    return TotalDays;
  };

  const formatter = new Intl.NumberFormat(['ban', 'id'], {
    maximumFractionDigits: 2,
  });

  return (
    <S.Wrapper>
      <S.Description>
        <b>Atenção participante,</b>
        <br />
        Seu cadastro foi inativado. Realize o resgate dos seus pontos acumulados
        dentro de {days(new Date(limit_date), new Date())} dias para continuar
        aproveitando as vantagens do Giga. Após esse período, seus pontos irão
        expirar. NÃO PERCA TEMPO!
        <br />
        Seu saldo atual é <b>{formatter.format(balance)}</b>{' '}
        {balance === 0 || balance > 1 ? 'pontos' : 'ponto'}!
      </S.Description>
      <S.Buttons>
        <S.DeleteBalance onClick={deleteBalance}>
          Desistir do meu saldo
        </S.DeleteBalance>
        <S.Button href={url} target="_blank">
          Ok
        </S.Button>
      </S.Buttons>
    </S.Wrapper>
  );
};

export default SSOTurnoff;
