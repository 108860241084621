import StatusTag from '~/components/NexAdmin/StatusTag';
import ContainerWithTitle from '~/components/NexAdmin/ContainerWithTitle';
import {
  ContentInner,
  ContentWrapper,
  HeaderWrapper,
} from '~/styles/Layouts/NexAdmin/wrappers';
import { useState, useRef } from 'react';
import api from '~/services/api';
import { parseJwt } from '~/services/auth';
import Loading from '~/components/Loading';
import Table from '~/components/NexAdmin/Table';
import Counter from '~/components/NexAdmin/Counter';
import { Input } from '~/components/NexAdmin/Inputs/index';
import { Button } from '~/components/NexAdmin/Buttons/index';
import { Form } from '@unform/web';
import { appendObjectInFormData } from '~/utils';
import { HTTPErrorCallback } from '~/Helpers/Error';
import * as S from './styles';
import DownloadHook from '~/hooks/NexAdmin/DownloadHook';
import Download from '~/components/NexAdmin/Download/Download';
import columns from './columns';
import { buildReportName } from '~/utils/NexAdmin/utils';
import { useModal } from '~/hooks/NexAdmin/MoldalHook';
import ExtractDescription from '~/components/NexAdmin/Modal/Descriptions/extractDescription';

const List = () => {
  const [loading, setLoading] = useState(false);
  const [extract, setExtract] = useState([]);
  const [count, setCount] = useState(0);
  const current_user = parseJwt();
  const formRef = useRef();
  const { openModal } = useModal();

  // Função para criar o formData reutilizável
  const createFormData = (data, download = false) => {
    const form = new FormData();
    appendObjectInFormData(form, {
      faixa: '',
      ...data,
      download: download ? true : '',
    });
    return new URLSearchParams(form);
  };

  // Submissão do formulário
  const handleSubmit = async (data) => {
    setLoading(true);
    const params = createFormData(data);
    try {
      const response = await api.get(`admin/usuario/historico-faixa?${params}`);
      const { count, data: dataFound } = response.data;

      const formattedData = dataFound.map((item) => ({
        nome: item.usu_nome,
        cpf: item.usu_cpf,
        label: item.label,
        inicioFaixa: new Date(item.ini_faixa),
        fimFaixa: new Date(item.fim_faixa),
      }));

      setExtract(formattedData);
      setCount(count);
    } catch (error) {
      HTTPErrorCallback(error);
    } finally {
      setLoading(false);
    }
  };

  // Função de download
  const handleDownload = () => {
    const data = formRef.current.getData();
    const params = createFormData(data, true); // Passando `true` para o parâmetro `download`
    return api.get(`admin/usuario/historico-faixa?${params}`, {
      responseType: 'blob',
    });
  };

  const beforeLoading = () => setLoading(true);

  const afterLoading = () => setLoading(false);

  const { execute, ref, urlFile, nameFile } = DownloadHook({
    externalCall: handleDownload,
    onError: HTTPErrorCallback,
    getFileName: buildReportName('Histórico de faixa'),
    beforeLoading,
    afterLoading,
  });

  // Definir colunas com base no canal do usuário
  const switchColumns = () => {
    switch (Number(current_user.canal_id)) {
      case 2:
      case 4:
        return columns['2'];
      default:
        return [];
    }
  };

  // Abrir modal
  const openThermsModal = () => {
    openModal({
      title: 'Histórico de Faixa',
      close: true,
      content: <ExtractDescription />,
    });
  };

  return (
    <>
      {loading && <Loading />}
      <HeaderWrapper>
        <StatusTag
          icon="statusSketch"
          title="Extrato"
          modalFunction={openThermsModal}
        />
      </HeaderWrapper>
      <ContentWrapper>
        <ContentInner withDetails>
          <ContainerWithTitle title="Histórico">
            <Form ref={formRef} onSubmit={handleSubmit}>
              <S.FormWrapper>
                <S.FormFilters>
                  <Input name="cpf" label="CPF/CNPJ" type="text" />{' '}
                  <Input name="initial_date" label="De:" type="date" />
                  <Input name="final_date" label="Até:" type="date" />
                </S.FormFilters>
                <Button type="submit">Consultar</Button>
              </S.FormWrapper>
              <S.DownloadWrapper>
                <a href={urlFile} download={nameFile} ref={ref} />
                <Download buttonName="Download" external={execute} />
              </S.DownloadWrapper>
            </Form>
            <Counter total={count} />
            <Table
              data={extract}
              columns={switchColumns()}
              dataTableProps={{ pagination: true }}
              header
              dropdownItems={false}
              hasDownload={false}
            />
          </ContainerWithTitle>
        </ContentInner>
      </ContentWrapper>
    </>
  );
};

export default List;
