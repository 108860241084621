import { useEffect, useState, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { AiOutlineDeploymentUnit } from 'react-icons/ai';
import { BiExit } from 'react-icons/bi';
import {
  CgTrophy,
  CgCalculator,
  CgLoadbarDoc,
  CgUserList,
  CgList,
} from 'react-icons/cg';
import { FiPlusSquare } from 'react-icons/fi';
import { RiDashboardLine } from 'react-icons/ri';
import { HTTPErrorCallback } from '~/Helpers/Error';
import api from '~/services/api';
import { parseJwt } from '~/services/auth';
import UseNotification from '~/hooks/NexAdmin/NotificationHook';
import Button from '../Button';
import * as S from './styles';
import UseTwoFactor from '~/hooks/UseTwoFactor';

const Sidebar = ({ open, setOpen }) => {
  const [loading, setLoading] = useState(false);
  const { notificationAmount } = UseNotification();
  const { requestCode, FEATURES, setCodeLoading } = UseTwoFactor();

  const isCatalogTurnedOff = process.env.REACT_APP_CATALOG_TURNOFF;

  const ssoStore = () => {
    requestCode(FEATURES['common-premmiar-sso'], (digits, setOpen) => {
      setLoading(true);
      setCodeLoading(true);

      api
        .get(`/loja/sso/${digits || null}`)
        .then((response) => {
          setOpen(false);
          setLoading(false);
          setCodeLoading(false);
          window.open(response.data.data);
        })
        .catch((error) => {
          setCodeLoading(false);
          setLoading(false);
          HTTPErrorCallback(error);
        });
    });
  };

  const handleCatalogClick = () => {
    alert('O catálogo está em manutenção.');
  };

  const logout = () => {
    localStorage.removeItem('userToken');
    localStorage.removeItem('canal');
  };

  return (
    <S.Container open={open} onScroll={(e) => e.stopPropagation()}>
      {![7].includes(parseJwt().canal_id) || parseJwt().id === 1025252 ? (
        <Button
          to="#"
          onClick={
            isCatalogTurnedOff === 'true'
              ? handleCatalogClick
              : () => ssoStore()
          }
        >
          <CgTrophy size={20} />
          {loading ? 'Carregando...' : 'Catálogo de Prêmios'}
        </Button>
      ) : (
        ' '
      )}

      <S.Menu>
        <li>
          <S.Link to="/dashboard" onClick={() => setOpen(!open)}>
            <RiDashboardLine size={20} />
            Página Inicial
          </S.Link>
        </li>
        <li>
          <S.Link to="/gigaline" onClick={() => setOpen(!open)}>
            <AiOutlineDeploymentUnit size={20} />
            Gigaline
          </S.Link>
        </li>

        {parseJwt().canal_id !== 7 &&
        parseJwt().cargo !== 20 &&
        parseJwt().cargo !== 73 ? (
          <li>
            <S.Link
              to="/extrato"
              className="preventclick"
              onClick={() => setOpen(!open)}
            >
              <CgUserList size={20} />
              Extrato
            </S.Link>
            <ul>
              <li>
                <S.Link to="/extrato" onClick={() => setOpen(!open)}>
                  Meus Resgastes
                </S.Link>
              </li>
              <li>
                <S.Link to="/extrato" onClick={() => setOpen(!open)}>
                  Meus Pontos
                </S.Link>
              </li>
            </ul>
          </li>
        ) : (
          ' '
        )}

        {parseJwt().canal_id !== 7 && parseJwt().canal_id !== 9 ? (
          <>
            <li>
              <S.Link to="/tabela-de-pontos" onClick={() => setOpen(!open)}>
                <CgLoadbarDoc size={20} />
                Tabela de Pontos
              </S.Link>
            </li>

            {parseJwt().canal_id !== 5 && (
              <li>
                <S.Link to="/calculadora" onClick={() => setOpen(!open)}>
                  <CgCalculator size={20} />
                  Calculadora
                </S.Link>
              </li>
            )}
          </>
        ) : (
          ' '
        )}

        {parseJwt().cargo === 90 && ( //gn AA cargo_id = 90
          <li>
            <S.Link to="/bolsao-do-gn" onClick={() => setOpen(!open)}>
              <CgList size={20} />
              Bolsão do GN
            </S.Link>
          </li>
        )}

        {parseJwt().cargo === 73 && (
          <li>
            <S.Link to="/bolsao-do-gn" onClick={() => setOpen(!open)}>
              <CgList size={20} />
              Giga GN
            </S.Link>
          </li>
        )}
        {/* {parseJwt().cargo === 73 && (
          <li>
            <S.Link to="/bolsao-do-gn" onClick={() => setOpen(!open)}>
              <CgList size={20} />
              Giga GN
            </S.Link>
          </li>
        )} */}

        {[5].includes(parseJwt().cargo) && ( //lad AA cargo_id 66, 69
          <li>
            <S.Link to="/bolsao-do-lad" onClick={() => setOpen(!open)}>
              <CgList size={20} />
              Bolsão do LAD
            </S.Link>
          </li>
        )}

        <li>
          <S.Link
            to="/saiba-mais"
            onClick={() => setOpen(!open)}
            className="preventclick"
          >
            <FiPlusSquare size={20} />
            Saiba mais
          </S.Link>
          <ul>
            <li>
              <S.Link to="/sobre-a-campanha" onClick={() => setOpen(!open)}>
                Sobre a campanha
              </S.Link>
            </li>
            <li>
              <S.Link to="regulamento" onClick={() => setOpen(!open)}>
                Regulamento
              </S.Link>
            </li>
            <li>
              <S.Link to="/faq" onClick={() => setOpen(!open)}>
                FAQ
              </S.Link>
            </li>
          </ul>
        </li>
        <li>
          <S.Link to="/ocorrencias">
            <FiPlusSquare size={20} />
            Ocorrências
            {notificationAmount.Tickets > 0 && <S.Notification />}
          </S.Link>
          {/* <ul>
            <li>
              <S.Link to="/criar-ocorrencias" onClick={() => setOpen(!open)}>
                Nova Ocorrência
              </S.Link>
            </li>
          </ul> */}
        </li>
        {isMobile && (
          <>
            <li>
              <S.Link to="/" onClick={() => logout()}>
                <BiExit size={20} />
                Sair
              </S.Link>
            </li>
            <li>
              <S.Back onClick={() => setOpen(!open)}>
                <img className="back" alt="Voltar" src="/images/back.png" />
              </S.Back>
            </li>
          </>
        )}
      </S.Menu>
    </S.Container>
  );
};

export default Sidebar;
